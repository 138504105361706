<template>
  <div
    v-if="analise === 'CBT'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color: rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &lt; 50
      </div>
    </div>
    <div>Resultado de exelência</div>
    <div class="box">
      <div style="background-color: rgba(34, 84, 255, 0.2); width: 15px; height: 15px;" />
      <div>
        50 a 100
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color:  rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        100 A 300
      </div>
    </div>
    <div>Potencial de melhoria</div>
    <div class="box">
      <div style="background-color:  rgba(255, 0, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &gt; 300
      </div>
    </div>
    <div>Fora da legislação</div>
  </div>
  <div
    v-else-if="analise === 'CCS'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color:  rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &lt; 150
      </div>
    </div>
    <div>Resultado de exelência</div>
    <div class="box">
      <div style="background-color: rgba(34, 84, 255, 0.2); width: 15px; height: 15px;" />
      <div>
        150 a 300
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color:  rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        300 a 500
      </div>
    </div>
    <div>Potencial de melhoria</div>
    <div class="box">
      <div style="background-color:  rgba(255, 0, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &gt; 500
      </div>
    </div>
    <div>Fora da legislação</div>
  </div>
  <div
    v-else-if="analise === 'Gordura'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color: rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &gt; 4,10%
      </div>
    </div>
    <div>Resultado de exelência</div>
    <div class="box">
      <div style="background-color: rgba(34, 84, 255, 0.2); width: 15px; height: 15px;" />
      <div>
        3,81% a 4,10%
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color: rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        3,00% A 3,80%
      </div>
    </div>
    <div>Potencial de melhoria</div>
    <div class="box">
      <div style="background-color: rgba(255, 0, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &lt;  3,00%
      </div>
    </div>
    <div>Fora da legislação</div>
  </div>
  <div
    v-else-if="analise === 'Proteína'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color: rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &gt; 3,60%
      </div>
    </div>
    <div>Resultado de exelência</div>
    <div class="box">
      <div style="background-color: rgba(34, 84, 255, 0.2); width: 15px; height: 15px;" />
      <div>
        3,30% a 3,60%
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color: rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        2,90% A 3,30%
      </div>
    </div>
    <div>Potencial de melhoria</div>
    <div class="box">
      <div style="background-color: rgba(255, 0, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &lt; 2,90%
      </div>
    </div>
    <div>Fora da legislação</div>
  </div>
  <div
    v-else-if="analise === 'EST'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color: rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &gt; 12%
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color: rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &lt; 12%
      </div>
    </div>
    <div>Potencial de melhoria</div>
  </div>
  <div
    v-else-if="analise === 'ESD'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color:  rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &gt; 8,5%
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color:  rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &lt; 8,5%
      </div>
    </div>
    <div>Potencial de melhoria</div>
  </div>
  <div
    v-else-if="analise === 'Lactose'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color: rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &gt; 4,3
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color: rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &lt; 4,3
      </div>
    </div>
    <div>Potencial de melhoria</div>
  </div>
  <div
    v-else-if="analise === 'CMP'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color: rgba(181, 229, 222, 0.4); width: 15px; height: 15px;" />
      <div>
        &lt; 30
      </div>
    </div>
    <div>Resultado de exelência</div>
    <div class="box">
      <div style="background-color: rgba(34, 84, 255, 0.2); width: 15px; height: 15px;" />
      <div>
        30 a 75
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color:  rgba(255, 0, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &gt; 75
      </div>
    </div>
    <div>Fora da legislação</div>
  </div>
  <div
    v-else-if="analise === 'NUL'"
    class="box-grid box-container"
  >
    <div class="box">
      <div style="background-color: rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &gt; 16
      </div>
    </div>
    <div>Potencial de melhoria</div>
    <div class="box">
      <div style="background-color:  rgba(34, 84, 255, 0.2); width: 15px; height: 15px;" />
      <div>
        8,5 a 16
      </div>
    </div>
    <div>Bom resultado</div>
    <div class="box">
      <div style="background-color:  rgba(255, 192, 0, 0.2); width: 15px; height: 15px;" />
      <div>
        &lt; 8,5
      </div>
    </div>
    <div>Potencial de melhoria</div>
  </div>
</template>

<script>
  export default {
    name: 'QualidadeGraficoLegendas',
    components: {
    },
    props: {
      analise: {
        type: String,
        default: () => '',
      },
      value: {
        type: String,
        default: () => '',
      },
      text: {
        type: String,
        default: () => '',
      },
    },
  }
</script>

<style>
  .box-grid {
    display: grid;
    min-width: 260px;
    width: 100%;
    max-width: 400px;
    font-weight: 600;
    color: grey;
  }

  .box-container {
    grid-template-columns: 1fr 2fr;
  }

  .box {
    display: flex;
    align-items:
    center; gap: 5px
  }
</style>

<template>
  <div v-if="selecaoFazenda === null || selecaoFazenda === ''">
    <v-alert
      color="warning"
      icon="mdi-cow"
      dark
      dismissible
    >
      <span><h2>Por favor selecione uma Fazenda no canto superior direito. </h2></span>
    </v-alert>
  </div>

  <base-loading v-else-if="!ready" />

  <v-container
    v-else
    fluid
    style="padding: 30px;"
  >
    <div
      class="font-weight-bold"
      style="font-size: 34px; color: #183B94; margin-bottom: 30px"
    >
      Qualidade
    </div>
    <v-window
      v-model="window"
    >
      <v-window-item
        v-for="periodo in anualOuMensal"
        :key="periodo"
      >
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
            <v-date-picker
              v-model="mes"
              class="mt-0 mr-0"
              type="month"
              color="#183B94"
              width="100%"
              :show-current="false"
              elevation="4"
              @change="handleDataFilter"
            />

            <material-card
              icon="mdi-flask-outline"
              class="v-card--material-chart"
              color="#183B94"
              v-bind="$attrs"
              elevation="4"
              style="min-height: 395px;"
            >
              <template v-slot:after-heading>
                <div
                  style="display: flex; flex-direction: row; justify-content: space-between;"
                >
                  <h3
                    v-if="periodo === 'Mensal'"
                    class="display-2 pt-2 grey--text font-weight-light"
                    v-text="'Médias Mensais e Trimestral'"
                  />
                  <h3
                    v-else
                    class="display-2 pt-2 grey--text font-weight-light"
                    v-text="'Médias Mensais'"
                  />
                </div>
              </template>

              <v-tabs-items
                v-model="tab"
                style="border-radius: 8px"
              >
                <v-tab-item
                  v-for="(analise, index) in analiseArray"
                  :key="index"
                >
                  <v-col
                    v-for="(column, indcol) in resultadoTrimestreOuAno"
                    :key="indcol"
                    cols="12"
                    style="padding: 10px 0px 0px 0px;"
                  >
                    <v-card
                      style="margin: 0px;"
                      outlined
                    >
                      <v-simple-table
                        fixed-header
                        height="295px"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                width="40%"
                                class="text-center"
                                style="color: #183B94; font-weight: 700; background-color: #d1d1d1;"
                              >
                                Mês
                              </th>
                              <th
                                class="text-center"
                                style="color: #183B94; font-weight: 700; background-color: #d1d1d1;"
                              >
                                {{ analise }}
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            style="margin-right: 295px;"
                          >
                            <tr
                              v-for="(item, indrow) in Object.keys(column)"
                              :key="indrow"
                            >
                              <td
                                style="text-align: start; color: black; font-size: 13px; font-weight: normal;"
                              >
                                {{ column[item].mesAno }}
                              </td>
                              <td
                                v-if="analise === 'CBT'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].cpp_mes !== 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter(column[item].cpp_mes, 0 ) }} (x 1000 UFC/mL)
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'CCS'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].ccs_mes !== 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter(column[item].ccs_mes, 0) }} (x 1000 CS/mL)
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'Gordura'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].gord_mes > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].gord_mes, 2 ) }} %
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'Proteína'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].prot_mes > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].prot_mes, 2 ) }} %
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'EST'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].est_mes > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].est_mes, 2 ) }} %
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'ESD'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].esd_mes > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].esd_mes, 2 ) }} %
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'Lactose'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].lact_mes > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].lact_mes, 2 ) }} %
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'CMP'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].cmp_mes > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].cmp_mes, 2 ) }} mg/L
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'NUL'"
                                style="text-align: right; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="column[item].nul_mes > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter(column[item].nul_mes) }} mg/dL
                                </span>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot v-if="periodo === 'Mensal'">
                            <tr
                              v-for="(item, indrow) in Object.keys(column)"
                              :key="indrow"
                            >
                              <td
                                v-if="indrow === Object.keys(column).length-1"
                                style="text-align: start; padding-right: 0px; font-size: 13px; font-weight: bold; color: #183B94;"
                              >
                                Média Trimestral
                              </td>
                              <td
                                v-if="analise === 'CBT' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].cpp_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].cpp_media, 0 ) }} (x 1000 UFC/mL)
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'CCS' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].ccs_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].ccs_media, 0 ) }}  (x 1000 CS/mL)
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'Gordura' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].gord_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].gord_media, 2 ) }} %
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'Proteína' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].prot_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].prot_media, 2 ) }} %
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'EST' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].est_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].est_media, 2 ) }} %
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'ESD' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].esd_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].esd_media, 2 ) }} %
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if=" analise === 'Lactose' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].prot_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].lact_media, 2 ) }} %
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'CMP' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].cmp_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].cmp_media, 2 ) }} mg/dL
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                              <td
                                v-else-if="analise === 'NUL' && indrow === Object.keys(column).length-1"
                                style="text-align: right; font-weight: bold; padding: 0px 10px 0px 0px;"
                              >
                                <span
                                  v-if="Object.keys(column).length-1 === 2 && column[item].prot_media > 0"
                                  style="color: #2DADED"
                                >
                                  {{ currencyFormatter( column[item].nul_media, 2 ) }} mg/dL
                                </span>
                                <span
                                  v-else
                                  style="color: #2DADED"
                                >
                                  ------------
                                </span>
                              </td>
                            </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-tab-item>
              </v-tabs-items>
            </material-card>
          </v-col>
          <v-col
            cols="12"
            lg="8"
            md="12"
            sm="12"
          >
            <v-btn-toggle
              v-model="window"
              active-class="selected-btn"
              style="opacity: 1; border-radius: 8px;"
            >
              <v-btn
                :min-width=" $vuetify.breakpoint.xs ? 120 : 270"
                height="60"
                elevation="4"
                style="font-size: 20px; font-weight: 700;"
                @click="handleDataFilter()"
              >
                Mensal
              </v-btn>

              <v-btn
                style="font-size: 20px; font-weight: 700;"
                :min-width=" $vuetify.breakpoint.xs ? 120 : 270"
                height="60"
                elevation="4"
                @click="yearSearch()"
              >
                Anual
              </v-btn>
            </v-btn-toggle>

            <div
              class="font-weight-bold"
              style="font-size: 20px; color: #183B94; margin-bottom: 16px; margin-top: 24px;"
            >
              Análise
            </div>
            <v-tabs
              v-model="tab"
              background-color="white"
              style="border-radius: 8px;"
              show-arrows
              centered
            >
              <v-tab
                v-for="(sigla, indextab) in analiseArray"
                :key="indextab"
                v-model="tab"
                style="text-transform: none"
              >
                {{ sigla }}
              </v-tab>
            </v-tabs>
            <v-card
              elevation="5"
              style="border-radius: 8px;"
            >
              <v-tabs-items
                v-model="tab"
                style="height: 610px;"
              >
                <v-tab-item
                  v-for="(analise, index) in analiseArray"
                  :key="index"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      style="margin-top: 10px;"
                    >
                      <div v-if="periodo === 'Mensal'">
                        <div v-if="analise === 'CBT'">
                          <material-chart-card
                            v-if="colunasMensais.cbt"
                            ref="grfcbt"
                            :options="optEvolucaoCBT"
                            :data="colunasMensais.cbt"
                            class="mt-5"
                            type="Bar"
                            title="Amostras de contagem bacteriana total (CBT)"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 300 x (1000 UFC/mL)"
                            sub-text-color-right="#558b2f"
                            :sub-click-right="mensagemCbt"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                          />
                        </div>
                        <div v-else-if="analise === 'CCS'">
                          <material-chart-card
                            v-if="colunasMensais.ccs"
                            :options="optEvolucaoCCS"
                            :data="colunasMensais.ccs"
                            class="mt-5"
                            title="Amostras de contagem de células somáticas (CCS)"
                            type="Bar"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 500 x (1000 CS/mL)"
                            :sub-click-right="mensagemCcs"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                          />
                        </div>
                        <div v-else-if="analise === 'Gordura'">
                          <material-chart-card
                            v-if="colunasMensais.gord"
                            :options="optEvolucaoGORD"
                            :data="colunasMensais.gord"
                            class="mt-5"
                            title="Amostras de gordura"
                            type="Bar"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 3%"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemGORD"
                          />
                        </div>
                        <div v-else-if="analise === 'Proteína'">
                          <material-chart-card
                            v-if="colunasMensais.prot"
                            :options="optEvolucaoPROT"
                            :data="colunasMensais.prot"
                            class="mt-5"
                            type="Bar"
                            title="Amostras de proteína"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 2.9%"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemPROT"
                          />
                        </div>
                        <div v-else-if="analise === 'EST'">
                          <material-chart-card
                            v-if="colunasMensais.est"
                            :options="optEvolucaoEST"
                            :data="colunasMensais.est"
                            class="mt-5"
                            title="Amostras de extrato seco total (EST)"
                            type="Bar"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 11,5 %"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemEst"
                          />
                        </div>
                        <div v-else-if="analise === 'ESD'">
                          <material-chart-card
                            v-if="colunasMensais.esd"
                            :options="optEvolucaoESD"
                            :data="colunasMensais.esd"
                            class="mt-5"
                            type="Bar"
                            title="Amostras de extrato seco desengordurado (ESD)"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 8,5%"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemEsd"
                          />
                        </div>
                        <div v-else-if="analise === 'Lactose'">
                          <material-chart-card
                            v-if="colunasMensais.lact"
                            :options="optEvolucaoLACT"
                            :data="colunasMensais.lact"
                            class="mt-5"
                            title="Amostras de lactose"
                            type="Bar"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 4,3 %"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemLact"
                          />
                        </div>
                        <div v-else-if="analise === 'CMP'">
                          <material-chart-card
                            v-if="colunasMensais.cmp"
                            :options="optEvolucaoCMP"
                            :data="colunasMensais.cmp"
                            title="Amostras de caseinomacropeptídeo (CMP)"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 30 (mg/L)"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemCmp"
                          />
                        </div>
                        <div v-else-if="analise === 'NUL'">
                          <material-chart-card
                            v-if="colunasMensais.nul"
                            :options="optEvolucaoNUL"
                            :data="colunasMensais.nul"
                            class="mt-5"
                            type="Bar"
                            title="Amostras de nitrogênio ureico (NUL)"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 10 a 14 (mg/dL)"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemNul"
                          />
                        </div>
                      </div>
                      <div v-else-if="periodo === 'Anual'">
                        <div v-if="analise === 'CBT'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.cbt"
                            :options="optEvolucaoCBT"
                            :data="resultadoGraficoAnual.cbt"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensais contagem bacteriana total (CBT)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 300 x (1000 UFC/mL)"
                            :sub-click-right="mensagemCbt"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                          />
                        </div>
                        <div v-else-if="analise === 'CCS'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.ccs"
                            :options="optEvolucaoCCS"
                            :data="resultadoGraficoAnual.ccs"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensais contagem células somáticas (CCS)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 500 x (1000 CS/mL)"
                            :sub-click-right="mensagemCcs"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                          />
                        </div>
                        <div v-else-if="analise === 'Gordura'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.gord"
                            :options="optEvolucaoGORD"
                            :data="resultadoGraficoAnual.gord"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensais gordura (GORD)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 3%"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemGORD"
                          />
                        </div>
                        <div v-else-if="analise === 'Proteína'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.prot"
                            :options="optEvolucaoPROT"
                            :data="resultadoGraficoAnual.prot"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensais proteína (PROT)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 2.9%"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemPROT"
                          />
                        </div>
                        <div v-else-if="analise === 'EST'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.est"
                            :options="optEvolucaoEST"
                            :data="resultadoGraficoAnual.est"
                            class="mt-5"
                            type="Bar"
                            title="Médias mensais extrato seco total (EST)"
                            color="#143693"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 11,5 %"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemEst"
                          />
                        </div>
                        <div v-else-if="analise === 'ESD'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.esd"
                            :options="optEvolucaoESD"
                            :data="resultadoGraficoAnual.esd"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensais extrato seco desengordurado (ESD)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 8.5%"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                          />
                        </div>
                        <div v-else-if="analise === 'NUL'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.nul"
                            :options="optEvolucaoNUL"
                            :data="resultadoGraficoAnual.nul"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensias nitrogênio ureico (NUL)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 10 a 14 (mg/dL)"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemNul"
                          />
                        </div>
                        <div v-else-if="analise === 'CMP'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.cmp"
                            :options="optEvolucaoCMP"
                            :data="resultadoGraficoAnual.cmp"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensais caseinomacropeptídeo (CMP)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 30 (mg/L)"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemCmp"
                          />
                        </div>
                        <div v-else-if="analise === 'Lactose'">
                          <material-chart-card
                            v-if="resultadoGraficoAnual.lact"
                            :options="optEvolucaoLACT"
                            :data="resultadoGraficoAnual.lact"
                            class="mt-5"
                            type="Bar"
                            color="#143693"
                            title="Médias mensais lactose (LACT)"
                            icon="mdi-chart-timeline-variant-shimmer"
                            sub-icon-right="mdi-flask"
                            sub-icon-color-right="#558b2f"
                            sub-text-right="Referência: 4,3 %"
                            elevation="0"
                            max-width="100%"
                            min-height="430px"
                            :sub-click-right="mensagemLact"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      style="margin: 10px; display: flex; flex-direction: column; align-items: center;"
                    >
                      <qualidade-grafico-legendas
                        :analise="analise"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-container>
</template>
<script>
  import { mapActions, mapState } from 'vuex'
  import QualidadeStore, { BOOTSTRAP_FAZENDA } from '@/store/modules/qualidades'
  import { currencyFormatter } from '../../utils/formatter'
  import MaterialCard from '../../components/base/MaterialCard'
  import QualidadeGraficoLegendas from './QualidadeGraficoLegendas.vue'
  import Tooltips from 'chartist-plugin-tooltips-updated'
  import moment from 'moment'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import Swal from 'sweetalert2'

  export default {
    name: 'QualidadeProdutor',
    components: {
      MaterialCard,
      QualidadeGraficoLegendas,
      MaterialChartCard,
    },
    data () {
      return {
        currencyFormatter,
        mes: new Date().toISOString().slice(0, 7),
        window: 0,
        anualOuMensal: ['Mensal', 'Anual'],
        tab: 0,
        analiseArray: ['CBT', 'CCS', 'Gordura', 'Proteína', 'EST', 'ESD', 'NUL', 'CMP', 'Lactose'],
        meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        periodoState: 'Mensal',
        chartOptions: {
          lineSmooth: false,
        },
        optEvolucaoGORD: {
          low: 2,
          high: 5,
          axisY: {
            onlyInteger: true,
            offset: 50,
            labelInterpolationFnc: function (value) {
              return currencyFormatter(value, 2) + ' %'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return currencyFormatter(value, 2) },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return currencyFormatter(value, 2) },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 2, to: 3.00, color: 'red' },
                  { from: 3.00, to: 3.80, color: 'yellow' },
                  { from: 3.80, to: 4.10, color: 'blue' },
                  { from: 4.10, to: 6, color: 'green' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoPROT: {
          low: 2,
          high: 5,
          axisY: {
            onlyInteger: true,
            offset: 50,
            labelInterpolationFnc: function (value) {
              return currencyFormatter(value, 2) + ' %'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return currencyFormatter(value, 2) },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return currencyFormatter(value, 2) },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 2, to: 2.90, color: 'red' },
                  { from: 2.90, to: 3.30, color: 'yellow' },
                  { from: 3.30, to: 3.60, color: 'blue' },
                  { from: 3.60, to: 6, color: 'green' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoEST: {
          low: 6,
          axisY: {
            onlyInteger: true,
            offset: 70,
            labelInterpolationFnc: function (value) {
              return currencyFormatter(value, 2) + ' %'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return currencyFormatter(value, 2) },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return currencyFormatter(value, 2) },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 6, to: 12, color: 'yellow' },
                  { from: 12, to: 50, color: 'blue' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoESD: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            labelInterpolationFnc: function (value) {
              return value + ' %'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 0, to: 8.5, color: 'yellow' },
                  { from: 8.5, to: 16, color: 'blue' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoLACT: {
          low: 2,
          axisY: {
            onlyInteger: true,
            offset: 50,
            labelInterpolationFnc: function (value) {
              return value + ',00%'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return currencyFormatter(value, 2) },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return currencyFormatter(value, 2) },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 2, to: 4.3, color: 'yellow' },
                  { from: 4.3, to: 7, color: 'blue' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoCMP: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            labelInterpolationFnc: function (value) {
              return value + ' %'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 0, to: 30, color: 'green' },
                  { from: 30, to: 75, color: 'blue' },
                  { from: 75, to: 100, color: 'red' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoNUL: {
          low: 4,
          axisY: {
            onlyInteger: true,
            offset: 50,
            labelInterpolationFnc: function (value) {
              return value
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 4, to: 8.5, color: 'yellow' },
                  { from: 8.5, to: 16, color: 'blue' },
                  { from: 16, to: 50, color: 'green' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }
    },
    computed: {
      ...mapState('fazendas', ['selecaoFazenda']),
      ...mapState('qualidades', ['ready', 'qualidade_dia', 'media_qualidades']),
      ...mapState({ user: state => state.user }),
      // ...mapGetters(['hasPermission']),
      optEvolucaoCBT () {
        const dados = this.periodoState === 'Mensal' ? this.colunasMensais.cbt : this.resultadoGraficoAnual.cbt
        const maxValue = Math.max(...dados.series.flat())
        const adjustedHigh = maxValue <= 300 ? 300 : Math.ceil((maxValue - 300) / 800) * 800 + 30
        return {
          axisY: {
            onlyInteger: true,
            offset: 50,
            scaleMinSpace: 50,
            low: 0,
            high: adjustedHigh,
            labelInterpolationFnc: function (value) {
              return value + 'K'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 0, to: 50, color: 'green' },
                  { from: 50, to: 100, color: 'blue' },
                  { from: 100, to: 300, color: 'yellow' },
                  { from: 300, to: 3000000, color: 'red' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      },
      optEvolucaoCCS () {
        const data = this.periodoState === 'Mensal' ? this.colunasMensais.ccs : this.resultadoGraficoAnual.ccs
        const maxValue = Math.max(...data.series.flat())
        const adjustedHigh = maxValue <= 500 ? 500 : Math.ceil((maxValue - 500) / 800) * 800 + 30
        return {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: adjustedHigh,
            labelInterpolationFnc: function (value) {
              return value + 'K'
            },
          },
          height: 290,
          plugins: [
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
            this.faixas(
              {
                faixas: [
                  { from: 0, to: 150, color: 'green' },
                  { from: 151, to: 300, color: 'blue' },
                  { from: 300, to: 500, color: 'yellow' },
                  { from: 500, to: 5000000, color: 'red' },
                ],
              }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      },
      resultadoTrimestreOuAno () {
        const dados = this.media_qualidades
        const columns = []

        dados.sort(function (a, b) {
          if (new Date(a.data_referencia) < new Date(b.data_referencia)) return -1
          if (new Date(a.data_referencia) > new Date(b.data_referencia)) return 1
          return 0
        })

        dados.forEach(element => {
          if (columns.find(linha => linha.data_referencia === element.data_referencia) === undefined) {
            columns.push({
              data_referencia: element.data_referencia,
              mesAno: this.meses[parseInt(element.data_referencia.slice(5, 7) - 1)].substring(0, 3) + '/' + element.data_referencia.slice(0, 4),
              cpp_mes: 0,
              cpp_media: 0,
              cpp_vlrf: 300,
              ccs_mes: 0,
              ccs_media: 0,
              ccs_vlrf: 500,
              gord_mes: 0,
              gord_media: 0,
              gord_vlrf: 3.0,
              prot_mes: 0,
              prot_media: 0,
              prot_vlrf: 2.9,
              est_mes: 0,
              est_media: 0,
              est_vlrf: 11.5,
              esd_mes: 0,
              esd_media: 0,
              esd_vlrf: 0,
              lact_mes: 0,
              lact_media: 0,
              lact_vlrf: 4.3,
              cmp_mes: 0,
              cmp_media: 0,
              cmp_vlrf: 0,
              nul_mes: 0,
              nul_media: 0,
              nul_vlrf: 10,
            })
          }
        })

        dados.forEach(element => {
          if (element.sigla_analise === 'CCS') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.ccs_mes = element.valor_mensal
            arrayElement.ccs_media = element.valor_trimestre
          } else if (element.sigla_analise === 'CBT' || element.sigla_analise === 'CPP') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.cpp_mes = element.valor_mensal
            arrayElement.cpp_media = element.valor_trimestre
          } else if (element.sigla_analise === 'GORD') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.gord_mes = element.valor_mensal
            arrayElement.gord_media = element.valor_trimestre
          } else if (element.sigla_analise === 'PROT') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.prot_mes = element.valor_mensal
            arrayElement.prot_media = element.valor_trimestre
          } else if (element.sigla_analise === 'SOLI') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.est_mes = element.valor_mensal
            arrayElement.est_media = element.valor_trimestre
          } else if (element.sigla_analise === 'ESD') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.esd_mes = element.valor_mensal
            arrayElement.esd_media = element.valor_trimestre
          } else if (element.sigla_analise === 'LACT') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.lact_mes = element.valor_mensal
            arrayElement.lact_media = element.valor_trimestre
          } else if (element.sigla_analise === 'CASE') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.cmp_mes = element.valor_mensal
            arrayElement.cmp_media = element.valor_trimestre
          } else if (element.sigla_analise === 'NU') {
            const arrayElement = columns.find(linha => linha.data_referencia === element.data_referencia)
            arrayElement.nul_mes = element.valor_mensal
            arrayElement.nul_media = element.valor_trimestre
          }
        })
        const resultado = this.window === 0 ? columns : columns.reverse()
        return { resultado }
      },
      resultadoGraficoAnual () {
        let dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'CBT')
        const resultado_cbt = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(Math.round(item.valor_mensal, 0))
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'CCS')
        const resultado_ccs = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(Math.round(item.valor_mensal, 0))
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'GORD')
        const resultado_gord = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(item.valor_mensal)
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'PROT')
        const resultado_prot = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(item.valor_mensal)
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'SOLI')
        const resultado_est = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(item.valor_mensal)
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'ESD')
        const resultado_esd = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(item.valor_mensal)
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'LACT')
        const resultado_lact = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(item.valor_mensal)
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'CASE')
        const resultado_cmp = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(item.valor_mensal)
          return acc
        }, { labels: [], series: [[]] })

        dadosFiltrados = this.media_qualidades.filter(item => item.sigla_analise === 'NU')
        const resultado_nul = dadosFiltrados.reduce((acc, item) => {
          const horario = moment(item.data_referencia, 'YYYY-MM-DD')
          const mesAno = horario.format('MMM/YYYY').replace('.', '') // Remove o ponto do final
          acc.labels.push(mesAno)
          acc.series[0].push(item.valor_mensal)
          return acc
        }, { labels: [], series: [[]] })

        return { cbt: resultado_cbt, ccs: resultado_ccs, gord: resultado_gord, prot: resultado_prot, est: resultado_est, esd: resultado_esd, lact: resultado_lact, cmp: resultado_cmp, nul: resultado_nul }
      },
      colunasMensais () {
        let dados = this.qualidade_dia.filter(item => item.sigla === 'CBT' || item.sigla === 'CPP').reverse()

        const resultado_cbt = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'CCS').reverse()
        const resultado_ccs = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'GORD').reverse()
        const resultado_gord = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'PROT').reverse()
        const resultado_prot = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'EST' || item.sigla === 'SOLI').reverse()
        const resultado_est = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'ESD').reverse()
        const resultado_esd = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'LACT').reverse()
        const resultado_lact = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'CMP' || item.sigla === 'CASE').reverse()
        const resultado_cmp = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })

        dados = this.qualidade_dia.filter(item => item.sigla === 'NUL' || item.sigla === 'NU').reverse()
        const resultado_nul = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return { cbt: resultado_cbt, ccs: resultado_ccs, gord: resultado_gord, prot: resultado_prot, est: resultado_est, esd: resultado_esd, lact: resultado_lact, cmp: resultado_cmp, nul: resultado_nul }
      },
    },
    created () {
      if (!this.$store.hasModule('qualidades')) { this.$store.registerModule('qualidades', QualidadeStore) }
      if (this.selecaoFazenda !== null) {
        const date = new Date()
        const data_i = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().slice(0, 10)
        const data_f = new Date(date.getFullYear(), date.getMonth(), 0).toISOString().slice(0, 10)
        this.mes = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().slice(0, 7)

        this.Fazenda = this.selecaoFazenda
        this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
      }
    },
    updated () {
      if (this.selecaoFazenda && this.Fazenda !== this.selecaoFazenda) {
        const date = new Date()
        const data_i = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().slice(0, 10)
        const data_f = new Date(date.getFullYear(), date.getMonth(), 0).toISOString().slice(0, 10)
        this.mes = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().slice(0, 7)
        this.Fazenda = this.selecaoFazenda
        this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
      }
    },
    methods: {
      ...mapActions('qualidades', [BOOTSTRAP_FAZENDA]),
      handleDataFilter (value) {
        this.periodoState = 'Mensal'
        if (value) {
          const data_i = new Date(value.slice(0, 4), value.slice(5, 7) - 1, 1).toISOString().slice(0, 10)
          const data_f = new Date(value.slice(0, 4), value.slice(5, 7), 0).toISOString().slice(0, 10)
          const dataAnoPassado = new Date(value.slice(0, 4) - 1, value.slice(5, 7) - 1, 1).toISOString().slice(0, 10)
          const dataInicialAjustada = new Date(new Date(dataAnoPassado).setMonth(new Date(dataAnoPassado).getMonth() + 2)).toISOString().slice(0, 10)

          if (this.window === 0) {
            this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
          } else {
            this.BOOTSTRAP_FAZENDA({ data_inicial: dataInicialAjustada, data_final: data_f, id: this.selecaoFazenda.id })
          }
        } else {
          const date = new Date()
          const data_i = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().slice(0, 10)
          const data_f = new Date(date.getFullYear(), date.getMonth(), 0).toISOString().slice(0, 10)
          this.mes = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().slice(0, 7)
          this.BOOTSTRAP_FAZENDA({ data_inicial: data_i, data_final: data_f, id: this.selecaoFazenda.id })
        }
      },
      yearSearch () {
        this.periodoState = 'Anual'
        const date = new Date()
        const dataAnoPassado = new Date(date.getFullYear() - 1, date.getMonth(), 1).toISOString().slice(0, 10)
        const data_f = new Date(date.getFullYear(), date.getMonth(), 0).toISOString().slice(0, 10)
        const dataInicialAjustada = new Date(new Date(dataAnoPassado).setMonth(new Date(dataAnoPassado).getMonth() + 2)).toISOString().slice(0, 10)
        this.mes = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().slice(0, 7)
        this.BOOTSTRAP_FAZENDA({ data_inicial: dataInicialAjustada, data_final: data_f, id: this.selecaoFazenda.id })
      },
      faixas (options) {
        const defaultOptions = {
          faixas: [
            { from: 0, to: 49, color: 'green' },
            { from: 50, to: 99.9, color: 'yellow' },
            { from: 100, to: 300, color: 'blue' },
            { from: 300, to: 3000000, color: 'red' },
          ],
        }

        options = Object.assign({}, defaultOptions, options)

        function addBackground (svg, chartRect, axisY) {
          options.faixas.forEach(function (faixa) {
            const y1 = axisY.projectValue(Math.max(faixa.from, faixa.to))
            const y2 = axisY.projectValue(Math.min(faixa.from, faixa.to))

            const chartHeight = chartRect.height()
            const height = Math.abs(y2 - y1)
            const rectY = Math.min(chartHeight - y1, chartHeight - y2) + 20

            svg.elem('rect', {
              x: chartRect.x1,
              y: rectY,
              width: chartRect.width(),
              height: height,
              fill: faixa.color,
              opacity: 0.2,
            })
          })
        }

        return function ctGridBackground (chart) {
          chart.on('created', function (context) {
            addBackground(context.svg, context.chartRect, context.axisY)
          })
        }
      },
      pontos (options) {
        const defaultOptions = {
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -10,
          },
          textAnchor: 'middle',
          align: 'top', // Ajustado para 'top' para garantir que o rótulo fique no topo da barra
          labelInterpolationFnc: this.$chartist.noop,
        }

        const labelPositionCalculation = {
          point (data) {
            return {
              x: data.x,
              y: data.y,
            }
          },
          bar: {
            top: function (data) {
              return {
                x: data.x1 + (data.x2 - data.x1) / 2,
                y: Math.min(data.y1, data.y2), // Ajusta para a posição mais alta da barra
              }
            },
          },
        }

        options = this.$chartist.extend({}, defaultOptions, options)

        function addLabel (position, data) {
          const value = data.value.x !== undefined && data.value.y
            ? (data.value.x + ', ' + data.value.y)
            : data.value.y || data.value.x

          data.group.elem('text', {
            x: position.x + options.labelOffset.x,
            y: position.y + options.labelOffset.y,
            style: 'text-anchor: ' + options.textAnchor,
          }, options.labelClass).text(options.labelInterpolationFnc(value))
        }

        return function ctPointLabels (chart) {
          chart.on('draw', function (data) {
            const positionCalculator = (labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align]) || labelPositionCalculation[data.type]
            if (positionCalculator) {
              addLabel(positionCalculator(data), data)
            }
          })
        }
      },
      mensagemCbt () {
        Swal.fire({
          title: 'Contagem Bacteriana Total - CBT',
          text: 'A CBT (contagem bacteriana total), nada mais é do que um indicador de contaminação bacteriana e reflete a higienização no processo de ordenha, limpeza dos equipamentos e resfriamento do leite. Os atuais limites vigentes determinados pela Instrução Normativa 76 e 77 são de 300 mil unidades formadoras de colônias por mililitro ( 300 mil UFC/mL ).',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemCcs () {
        Swal.fire({
          title: 'Contagem de células somáticas - CCS',
          text: 'A CCS (Contagem de células somáticas), consiste em uma importante ferramenta que indica a saúde da glândula mamária de vacas leiteiras. Vacas sadias e com boa saúde da glândula mamária possuem valores de CCS de até 200.000 células/mL de leite. Valores superiores indicam que há algum desequilíbrio na glândula mamária, possivelmente devido a ocorrência de mastite. Os Atuais limites vigentes determinados pela Instrução Normativa 76 e 77 são de 500 células por mililitro ( 500 mil cs/mL ).',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemGORD () {
        Swal.fire({
          title: 'Gordura - GORD',
          text: 'Os atuais limites vigentes determinados pela instrução normativa 76 e 77 determina teor mínimo de proteína total de 3,0g/100g (três gramas por cem gramas).',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemPROT () {
        Swal.fire({
          title: 'Proteína - PROT',
          text: 'Os atuais limites vigentes determinados pela instrução normativa 76 e 77 determina teor mínimo de proteína total de 2,9g/100g (dois inteiros e nove décimos de gramas por cem gramas).',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemEst () {
        Swal.fire({
          title: 'Extrato seco total - EST',
          text: 'O extrato seco total (EST) ou sólidos totais é o somatório da concentração de todos os componentes do leite exceto a água.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemEsd () {
        Swal.fire({
          title: 'Extrato seco desengordurado - ESD',
          text: 'O extrato seco desengordurado (ESD) compreendem-se todos os elementos do leite, menos a água e a gordura. Os componentes do leite permanecem em equilíbrio, de modo que a relação entre eles é muito estável.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemNul () {
        Swal.fire({
          title: 'Nitrogênio ureico no leite - NUL',
          text: 'O nitrogênio ureico no leite (NUL) é um indicativo da adequação ou excesso de amônia ruminal em relação à energia disponível para o crescimento microbiano no rúmen. Alta quantidade de proteína disponível no rúmen (degradável/solúvel) em relação à quantidade de carboidratos disponíveis resulta em altos níveis de nitrogênio ureico no leite.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemCmp () {
        Swal.fire({
          title: 'Caseinomacropeptídeo - CMP',
          text: 'Caseinomacropeptídeo ou CMP, é um fragmento da k-caseína, que é liberado na coagulação enzimática do leite na produção de queijos. O índice de CMP, é a principal análise que determina se houve uma possível adulteração do leite, através da adição de agua ou soro.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemLact () {
        Swal.fire({
          title: 'Lactose - LACT',
          text: 'A análise mensura a concentração da lactose, um açúcar, presente no leite. A concentração de lactose no leite é de aproximadamente 5% (4,7 a 5,2%). É um dos elementos mais estáveis sujeito a menor variação.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
    },

  }
</script>
<style lang="scss" scoped>
.selected-btn {
  background: #183B94 !important;
  color: rgb(255, 255, 255) !important;
}

.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
    opacity: 0 !important;
}

.ct-background-band {
  pointer-events: none;
}

.v-slide-group__next, .v-slide-group__prev {
min-width: 5px;
}
</style>
<style lang="sass" scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th, .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th
  background-color: transparent,
</style>
